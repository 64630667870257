import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  SignUpCommand,
  ConfirmSignUpCommand,
  ResendConfirmationCodeCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand
} from "@aws-sdk/client-cognito-identity-provider";
import config from "./config.json";

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});



export const storeCredentials = (username, password) => {
  localStorage.setItem('rememberedUser', JSON.stringify({ username, password: password }));
};


export const getStoredCredentials = () => {
  const storedUser = localStorage.getItem('rememberedUser');
  if (storedUser) {
    const { username, password } = JSON.parse(storedUser);
    return { username, password };
  }
  return null;
};


export const clearStoredCredentials = () => {
  localStorage.removeItem('rememberedUser');
};


export const signIn = async (userIdentifier, password, rememberMe = false) => {
  const params = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      PASSWORD: password,
    },
  };

  if (userIdentifier.includes('@')) {
    params.AuthParameters.USERNAME = userIdentifier;
  } else {
    params.AuthParameters.USERNAME = userIdentifier;
  }

  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);
    if (response.AuthenticationResult) {
      localStorage.setItem("idToken", response.AuthenticationResult.IdToken || '');
      localStorage.setItem("accessToken", response.AuthenticationResult.AccessToken || '');
      localStorage.setItem("refreshToken", response.AuthenticationResult.RefreshToken || '');

      if (rememberMe) {
        storeCredentials(userIdentifier, password);
      } else {
        clearStoredCredentials();
      }
      return response.AuthenticationResult;
    } else {
      throw new Error("Authentication failed: No AuthenticationResult");
    }
  } catch (error) {
    throw new Error(`Login failed: ${error.message}`);
  }
};


export const storeRefreshToken = (refreshToken) => {
  localStorage.setItem('refreshToken',  refreshToken || '');
};


export const getStoredRefreshToken = () => {
  const storedUser = localStorage.getItem('refreshToken');
  return storedUser || null;
};


export const clearStoredRefreshToken = () => {
  localStorage.removeItem('refreshToken');
};


export const refreshSession = async (refreshToken) => {
  const params = {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  };

  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);
    if (response.AuthenticationResult) {
      localStorage.setItem("idToken", response.AuthenticationResult.IdToken || '');
      localStorage.setItem("accessToken", response.AuthenticationResult.AccessToken || '');
      return response.AuthenticationResult;
    } else {
      throw new Error("Refresh failed: No AuthenticationResult");
    }
  } catch (error) {
    console.error("Refresh failed:", error);
    clearStoredRefreshToken();
    throw error;
  }
};


export const autoLogin = async () => {
  const storedData = getStoredRefreshToken();
  if (storedData) {
    try {
      return await refreshSession(storedData);
    } catch (error) {
      console.error("Auto-login failed:", error);
      clearStoredRefreshToken();
    }
  }
  return null;
};


export const signUp = async (username, email, password, phone_number, dob, gender, referralCode) => {
  const userAttributes = [
    {
      Name: "email",
      Value: email
    },
    {
      Name: "phone_number",
      Value: phone_number
    },
    {
      Name: "birthdate",
      Value: dob
    },
    {
      Name: "gender",
      Value: gender
    }
  ];

  if (referralCode) {
    userAttributes.push({
      Name: "custom:referral_code",
      Value: referralCode
    });
  }

  const params = {
    ClientId: config.clientId,
    Username: username,
    Password: password,
    UserAttributes: userAttributes,
  };

  try {
    const command = new SignUpCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;
  }
};


export const confirmSignUp = async (username, code) => {
  const params = {
    ClientId: config.clientId,
    Username: username,
    ConfirmationCode: code,
  };
  try {
    const command = new ConfirmSignUpCommand(params);
    await cognitoClient.send(command);
    return true;
  } catch (error) {
    console.error("Error confirming sign up: ", error);
    throw error;
  }
};


export const resendConfirmationCode = async (username) => {
  const params = {
    ClientId: config.clientId,
    Username: username,
  };
  try {
    const command = new ResendConfirmationCodeCommand(params);
    const response = await cognitoClient.send(command);
    console.log("Resend confirmation code response:", response);
    return response;
  } catch (error) {
    console.error("Error resending confirmation code: ", error);
    throw error;
  }
};


export const initiatePhoneAuth = async (phoneNumber) => {
  const params = {
    AuthFlow: "CUSTOM_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: phoneNumber,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);
    console.log("Initiate phone auth response:", response);
    return response;
  } catch (error) {
    console.error("Error initiating phone auth: ", error);
    console.error("Error details:", JSON.stringify(error, null, 2));
    throw error;
  }
};


export const verifyPhoneOTP = async (phoneNumber, otp) => {
  const params = {
    AuthFlow: "CUSTOM_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: phoneNumber,
      ANSWER: otp,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');
      return AuthenticationResult;
    }
  } catch (error) {
    console.error("Error verifying OTP: ", error);
    throw error;
  }
};


export const resendPhoneOTP = async (phoneNumber) => {
  const params = {
    ClientId: config.clientId,
    Username: phoneNumber,
  };
  try {
    const command = new ResendConfirmationCodeCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Error resending OTP: ", error);
    throw error;
  }
};


export const forgotPassword = async (username) => {
  const params = {
    ClientId: config.clientId,
    Username: username,
  };

  try {
    const command = new ForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    console.log("Forgot password initiated:", response);
    return response;
  } catch (error) {
    console.error("Error initiating forgot password: ", error);
    throw error;
  }
};


export const confirmForgotPassword = async (username, confirmationCode, newPassword) => {
  const params = {
    ClientId: config.clientId,
    Username: username,
    ConfirmationCode: confirmationCode,
    Password: newPassword,
  };

  try {
    const command = new ConfirmForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    console.log("Password reset confirmed:", response);
    return response;
  } catch (error) {
    console.error("Error confirming new password: ", error);
    throw error;
  }
};