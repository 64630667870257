import React, { useState, useEffect } from "react";
import Login from "./Components/Login/Login";
import Signup from "./Components/Signup/Signup";
import PhoneLogin from "./Components/Login/PhoneLogin";
import EmailConfirmation from "./Components/EmailConfirmation/EmailConfirmation";

const SSOContainer = () => {
  const [activeComponent, setActiveComponent] = useState("login");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("clientApplication") && searchParams.has("redirectUrl")) {
          sessionStorage.setItem("clientApplication", searchParams.get("clientApplication"));
          sessionStorage.setItem("redirectUrl", searchParams.get("redirectUrl"));
      }
}, []);

  const renderComponent = () => {
    switch (activeComponent) {
      case "login":
        return (
          <Login
            onSwitchComponent={setActiveComponent}
            email={email}
            setEmail={setEmail}
            usernameOrEmail={username}
            setUsernameOrEmail={setUsername}
          />
        );
      case "signup":
        return (
          <Signup
            onSwitchComponent={setActiveComponent}
            email={email}
            setEmail={setEmail}
            username={username}
            setUsername={setUsername}
          />
        );
      case "phoneLogin":
        return <PhoneLogin onSwitchComponent={setActiveComponent} />;
      case "emailConfirm":
      case "forgetPassword":
        return (
          <EmailConfirmation
            activeComponent={activeComponent}
            onSwitchComponent={setActiveComponent}
            email={email}
            username={username}
          />
        );
      default:
        return (
          <Login
            onSwitchComponent={setActiveComponent}
            email={email}
            username={username}
          />
        );
    }
  };

  return (
      <div >{renderComponent()}</div>
  );
};

export default SSOContainer;
