import React, { useEffect, useRef, useState } from "react";
import "./PhoneLogin.css";
import CountryCode from '../../assets/CountryCode.json';
import { initiatePhoneAuth, verifyPhoneOTP, resendPhoneOTP } from "../../../Services/AuthService";

const OTP_LENGTH = 6;
const RESEND_TIMEOUT = 60;

const PhoneLogin = ({ onSwitchComponent }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("IN");
  const [error, setError] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otpInput, setOtpInput] = useState(new Array(OTP_LENGTH).fill(""));
  const [countryCodes, setCountryCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const otpInputRef = useRef([]);

  useEffect(() => {
    setCountryCodes(CountryCode);
  }, []);

  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const phoneRegex = /^[0-9]*$/;
    if (phoneRegex.test(value)) {
      setPhoneNumber(value);
      if (value.length === 10) {
        setError("");
      } else {
        setError("Please enter a valid 10-digit phone number.");
      }
    } else {
      setError("Only numeric values are allowed.");
    }
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!error && phoneNumber.length === 10) {
      setIsLoading(true);
      try {
        const selectedCountry = countryCodes.find(
          (country) => country.code === countryCode
        );
        const fullPhoneNumber = `+${selectedCountry.dial_code.replace(/[^\d]/g, '')}${phoneNumber}`;
        console.log("Full phone number:", fullPhoneNumber);
        await initiatePhoneAuth(fullPhoneNumber);
        setShowOtp(true);
        setError("");
        setResendTimer(RESEND_TIMEOUT);
      } catch (error) {
        setError("Failed to send OTP. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onOtpSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const selectedCountry = countryCodes.find(
        (country) => country.code === countryCode
      );
      const fullPhoneNumber = `${selectedCountry.dial_code}${phoneNumber}`;
      const otp = otpInput.join("");
      await verifyPhoneOTP(fullPhoneNumber, otp);
      console.log("Phone login successful");
      setError("");
    } catch (error) {
      setError("Invalid OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpInput = (index, e) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtpInput = [...otpInput];
      newOtpInput[index] = value;
      setOtpInput(newOtpInput);

      if (value !== "" && index < OTP_LENGTH - 1) {
        otpInputRef.current[index + 1]?.focus();
      }

      if (value === "" && index > 0) {
        otpInputRef.current[index - 1]?.focus();
      }
    }
  };

  const otpClickHandler = (index) => {
    otpInputRef.current[index]?.select();
  };

  const otpKeydownHandler = (index, e) => {
    if (e.key === "Backspace" && otpInput[index] === "" && index > 0) {
      otpInputRef.current[index - 1]?.focus();
    }
  };

  const handleResendOTP = async () => {
    if (resendTimer > 0) return;
    setIsLoading(true);
    try {
      const selectedCountry = countryCodes.find(
        (country) => country.code === countryCode
      );
      const fullPhoneNumber = `+${selectedCountry.dial_code.replace(/[^\d]/g, '')}${phoneNumber}`;
      console.log("Full phone number:", fullPhoneNumber);
      await resendPhoneOTP(fullPhoneNumber);
      setError("OTP resent successfully");
      setResendTimer(RESEND_TIMEOUT);
    } catch (error) {
      setError("Failed to resend OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const selectedCountry = countryCodes.find(
    (country) => country.code === countryCode
  );

  return (
    <section className="phoneLogin-section flex flex-col items-center justify-center w-screen overflow-x-hidden">
      {!showOtp ? (
        <>
          <header className="w-fit font-bold text-4xl border-b-2 border-[#edae00] py-9">
            Login
          </header>

          <form
            className="w-[35rem] mt-28 input-container flex justify-center flex-col"
            onSubmit={handleSubmit}
          >
            <div className="flex items-center gap-2">
              <select
                value={countryCode}
                onChange={handleCountryCodeChange}
                className="rounded-full px-1 py-3 text-lg outline-none border-none text-[#595959] country-code"
              >
                {countryCodes.map((code) => (
                  <option key={code.code} value={code.code}>
                    {code.code} ({code.dial_code})
                  </option>
                ))}
              </select>

              <input
                type="text"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className="input-bar"
              />
            </div>
            {error && (
              <p className="text-red-500 ml-5 text-xs font-semibold">{error}</p>
            )}
            <button
              className="w-full font-semibold rounded-full hover:bg-black hover:bg-opacity-60 hover:border-[#edae00] border-2 transition-all duration-300 ease-in-out border-[#edae00] mt-5 disabled:bg-[#c7af4e] disabled:border-[#c7af4e] disabled:cursor-not-allowed bg-[#edae00] text-center text-lg px-10 mb-4 py-2"
              disabled={phoneNumber.length !== 10 || error !== "" || isLoading}
              type="submit"
            >
              {isLoading ? "Sending..." : "Send OTP"}
            </button>
          </form>
          <button
            onClick={() => onSwitchComponent('login')}
            className="mt-4 text-lg underline font-semibold hover:text-yellow-500 duration-150"
          >
            Back to Email Login
          </button>
        </>
      ) : (
        <>
          <header className="text-4xl border-b-2 border-[#edae00] font-bold py-9 otpInputHeading">
            Enter OTP
          </header>
          <p className="font-bold text-base mt-4">Sent to {selectedCountry?.dial_code} {phoneNumber}</p>
          <form
            onSubmit={onOtpSubmit}
            className="flex items-center justify-center flex-col gap-2 mt-5"
          >
            <div className="flex gap-2 mt-12">
              {otpInput.map((val, index) => (
                <input
                  type="text"
                  key={index}
                  value={val}
                  onChange={(e) => handleOtpInput(index, e)}
                  onClick={() => otpClickHandler(index)}
                  onKeyDown={(e) => otpKeydownHandler(index, e)}
                  ref={(input) => (otpInputRef.current[index] = input)}
                  className="text-[#595959] text-lg size-10 text-center font-semibold rounded-xl outline-none"
                  maxLength={1}
                />
              ))}
            </div>
            {error && (
              <p className="text-red-500 text-xs font-semibold mt-2">{error}</p>
            )}
            <button
              className="bg-[#edae00] text-lg px-5 mb-7 py-2 rounded-full hover:bg-black hover:bg-opacity-60 hover:border-[#edae00] border-2 transition-all duration-300 ease-in-out border-[#edae00] disabled:bg-[#c7af4e] disabled:border-[#c7af4e] mt-5 disabled:cursor-not-allowed"
              type="submit"
              disabled={otpInput.includes("") || isLoading}
            >
              {isLoading ? "Verifying..." : "Verify OTP"}
            </button>
          </form>
          <button
            onClick={handleResendOTP}
            className={`mt-2 text-base underline font-semibold ${resendTimer > 0 ? 'text-gray-400 cursor-not-allowed' : ''}`}
            disabled={isLoading || resendTimer > 0}
          >
            {resendTimer > 0 ? `Resend OTP (${resendTimer}s)` : 'Resend OTP'}
          </button>
          <button
            onClick={() => onSwitchComponent('login')}
            className="mt-4 text-base underline font-semibold"
          >
            Back to Email Login
          </button>
        </>
      )}
    </section>
  );
};

export default PhoneLogin;