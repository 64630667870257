import { useState } from "react";
import "./Signup.css";
import LoginIcon from "@mui/icons-material/Login";
import GoogleIcon from "@mui/icons-material/Google";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CountryCode from '../../assets/CountryCode.json';
import { signUp } from './../../../Services/AuthService';
import { Alert, Snackbar } from "@mui/material";


const Signup = ({ onSwitchComponent, setEmail, email, username, setUsername }) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState('+91');
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [referralCode, setReferralCode] = useState("");

  const [type, setType] = useState("text")
  const [errors, setErrors] = useState({
    email: "",
    username: "",
    password: "",
    phoneNumber: "",
    dob: "",
    gender: "",
  });



  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("info");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const re = /^[0-9]{10}$/;
    return re.test(phoneNumber);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    let errors = [];

    if (password.length < minLength) {
      errors.push("Password must be at least 8 characters long");
    }
    if (!hasLowerCase) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!hasUpperCase) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!hasNumber) {
      errors.push("Password must contain at least one number");
    }
    if (!hasSpecialChar) {
      errors.push("Password must contain at least one special character");
    }

    return errors;
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      username: value ? "" : "Username is required",
    }));
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: value && validateEmail(value) ? "" : "Valid email is required",
    }));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    const passwordErrors = validatePassword(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: passwordErrors,
    }));
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumber: value && validatePhoneNumber(value) ? "" : "Valid phone number is required",
    }));
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleGenderChange = (e) => {
    const value = e.target.value;
    setGender(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      gender: value ? "" : "Gender selection is required",
    }));
  };

  const handleDobChange = (e) => {
    const value = e.target.value;
    setDob(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      dob: value ? "" : "Date of birth is required",
    }));
  };

  const handleReferralCodeChange = (e) => {
    setReferralCode(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {
      email: email ? (validateEmail(email) ? "" : "Valid email is required") : "Email is required",
      username: username ? "" : "Username is required",
      password: validatePassword(password),
      phoneNumber: phoneNumber ? (validatePhoneNumber(phoneNumber) ? "" : "Valid phone number is required") : "Phone number is required",
      dob: dob ? "" : "Date of birth is required",
      gender: gender ? "" : "Gender selection is required",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => Array.isArray(error) ? error.length > 0 : error !== "");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const showToast = (message, severity) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const phoneNumberWithCountryCode = countryCode.concat(phoneNumber);
      await signUp(username, email, password, phoneNumberWithCountryCode, dob, gender, referralCode);
      showToast("Sign up successful! Please check your email to confirm your account.", "success");
      onSwitchComponent("emailConfirm", email, username);
    } catch (error) {
      showToast(`Sign up failed: ${error}`, "error");
    }
  };


  return (
    <section className="signup-section flex flex-col items-center justify-center" id="signup-section">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={toastSeverity}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      <header className="border-b-2 pb-10 border-[#edae00] text-4xl font-bold mb-10">Signup</header>
      <article>
        <form className="flex flex-col w-[35rem] input-container" onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Username"
              value={username}
              required
              onChange={handleUsernameChange}
              className="input-bar"
            />
            {errors.username && <span className="error">{errors.username}</span>}
          </div>

          <div className="mb-4">
            <input
              type="email"
              placeholder="Email"
              value={email}
              required
              onChange={handleEmailChange}
              className="input-bar"
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="mb-4 relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              required
              onChange={handlePasswordChange}
              className="input-bar"
            />
            <button
              type="button"
              className="absolute right-5 top-3 text-gray-600"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOffIcon className="pswdVisibility" /> : <VisibilityIcon className="pswdVisibility" />}
            </button>
            {errors.password.length > 0 && (
              <ul className="error-list mt-2">
                {errors.password.map((error, index) => (
                  <li key={index} className="error text-red-500 text-xs">{error}</li>
                ))}
              </ul>
            )}
          </div>


          <div className="mb-4">
            <div className="flex items-center justify-center gap-2">
              <select
                value={countryCode}
                onChange={handleCountryCodeChange}
                className="rounded-full px-[1.5px] py-3 text-lg outline-none border-none text-[#595959] country-code"
              >
                {CountryCode.map((code) => (
                  <option key={code.code} value={code.dial_code}>
                    {code.code} ({code.dial_code})
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Phone Number"
                value={phoneNumber}
                required
                onChange={handlePhoneNumberChange}
                className="input-bar"
              />
            </div>
            {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
          </div>

          <div className="flex items-center justify-center gap-4 two-row-input w-full mb-4">

            <input
              type={type}
              className="input-bar"
              onFocus={() => {
                setType("date");
                setTimeout(() => {
                  document.activeElement.showPicker();
                }, 0);
              }}
              onBlur={() => setType("text")}
              placeholder="dd-mm-yyyy"
              onChange={handleDobChange}
            />
            {errors.dob && <span className="error">{errors.dob}</span>}
            <input type="text" placeholder="Referral Code (optional)" className="input-bar" onChange={handleReferralCodeChange} />
          </div>

          <div className="flex items-center justify-around mb-4">
            <div className="flex items-center justify-center gap-1">
              <input
                type="radio"
                name="gender"
                value="male"
                checked={gender === "male"}
                onChange={handleGenderChange}
                id="male"
              />
              <label htmlFor="male" className="text-base label">Male</label>
            </div>
            <div className="flex items-center justify-center gap-2">
              <input
                type="radio"
                name="gender"
                value="female"
                checked={gender === "female"}
                onChange={handleGenderChange}
                id="female"
              />
              <label htmlFor="female" className="text-base label">Female</label>
            </div>
            <div className="flex items-center justify-center gap-2">
              <input
                type="radio"
                name="gender"
                value="preferNotToSay"
                checked={gender === "preferNotToSay"}
                onChange={handleGenderChange}
                id="preferNotToSay"
              />
              <label htmlFor="preferNotToSay" className="text-base label">Prefer not to say</label>
            </div>
          </div >
          <div className="mb-5">
            {errors.gender && <span className="error ml-10">{errors.gender}</span>}
          </div>
          <button type="submit" className="bg-[#edae00] w-full text-center text-lg font-semibold px-10 mb-4 py-2 rounded-full hover:bg-black hover:bg-opacity-60 hover:border-[#edae00] border-2 transition-all duration-300 ease-in-out border-[#edae00]">
            <LoginIcon className="relative bottom-[1px] left-[-5px] size-72" />
            <span className="">Signup</span>
          </button>
          <div className="w-full flex flex-col items-center justify-center">
            <span className="mt-4 text-xs mb-4">OR</span>
            <button type="button" className="extra-btn">
              <GoogleIcon className="mb-1 mr-2" />
              <span>Google</span>
            </button>
            <button
              type="button"
              onClick={() => onSwitchComponent('login')}
              className="text-base font-semibold underline mt-4"
            >
              Back to Login
            </button>
          </div>
        </form >
      </article >
    </section >
  );
};

export default Signup;