import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import GoogleIcon from "@mui/icons-material/Google";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { autoLogin, signIn } from "../../../Services/AuthService";
import "./Login.css";

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label className="flex items-center justify-center gap-2">
      <input type="checkbox" checked={value} onChange={onChange} />
      {label}
    </label>
  );
};

const Login = ({ onSwitchComponent, usernameOrEmail, setUsernameOrEmail }) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [type, setType] = useState("info");
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [rememberMe, setRememberMe] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    const attemptAutoLogin = async () => {
      const result = await autoLogin();
      if (result) {
        setMsg("Auto-login successful");
        setType("success");
        setOpen(true);
        if (sessionStorage.getItem("redirectUrl"))
          setRedirectUrl(
            () =>
              sessionStorage.getItem("redirectUrl") +
              "?accessToken=" +
              result.AccessToken +
              "&refreshToken=" +
              localStorage.getItem("refreshToken") +
              "&idToken=" +
              result.IdToken
          );
      }
    };
    attemptAutoLogin();
  }, []);

  useEffect(() => {
    if (redirectUrl && sessionStorage.getItem("redirectUrl")) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    let errors = [];

    if (password.length < minLength) {
      errors.push("Password must be at least 8 characters long");
    }
    if (!hasLowerCase) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!hasUpperCase) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!hasNumber) {
      errors.push("Password must contain at least one number");
    }
    if (!hasSpecialChar) {
      errors.push("Password must contain at least one special character");
    }

    return errors;
  };

  useEffect(() => {
    if (password) {
      const errors = validatePassword(password);
      setPasswordErrors(errors);
    } else {
      setPasswordErrors([]);
    }
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    setPasswordErrors([]);

    if (!usernameOrEmail) {
      setMsg("Username or Email is required");
      setType("error");
      setOpen(true);
      valid = false;
    }

    if (!password) {
      setPasswordErrors(["Password is required"]);
      valid = false;
    } else {
      const errors = validatePassword(password);
      if (errors.length > 0) {
        setPasswordErrors(errors);
        valid = false;
      }
    }

    if (!valid) {
      return;
    }

    try {
      const result = await signIn(usernameOrEmail, password, rememberMe);
      if (result) {
        setMsg("Login successful");
        setType("success");
        setOpen(true);
        if (sessionStorage.getItem("redirectUrl"))
          setRedirectUrl(
            sessionStorage.getItem("redirectUrl") +
              "?accessToken=" +
              result.AccessToken +
              "&refreshToken=" +
              result.RefreshToken +
              "&idToken=" +
              result.IdToken
          );
      }
    } catch (error) {
      console.error("Login error:", error);
      setMsg(error.message || "Login failed");
      setType("error");
      setOpen(true);
    }
  };
  const handleThirdPartyLogin = (provider) => {
    // Implementation for third-party login
  };

  return (
    <div className="login-container">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={type}
        >
          <h1>{msg}</h1>
        </Alert>
      </Snackbar>

      <section className="Login-section w-screen flex items-center justify-center">
        <main className="flex flex-col items-center justify-center">
          <header className="w-fit font-bold text-4xl border-b-2 border-[#edae00] py-9 mb-28">
            Login
          </header>
          <article>
            <div>
              <form
                onSubmit={handleSubmit}
                className="w-[35rem] flex items-center justify-center flex-col login-form"
              >
                <div className="w-full mb-5">
                  <input
                    type="text"
                    value={usernameOrEmail}
                    placeholder="Username or Email"
                    required
                    onChange={(e) => setUsernameOrEmail(e.target.value)}
                    className="input-bar"
                  />
                </div>

                <div className="w-full mb-5 relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="input-bar"
                  />
                  <button
                    type="button"
                    className="absolute right-6 top-3 text-gray-600"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityIcon className="pswdVisibility" />
                    ) : (
                      <VisibilityOffIcon className="pswdVisibility" />
                    )}
                  </button>
                  {passwordErrors.length > 0 && (
                    <ul className="error-list mt-2">
                      {passwordErrors.map((error, index) => (
                        <li
                          key={index}
                          className="text-red-500 text-xs font-semibold"
                        >
                          {error}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="w-full flex items-center justify-between px-5 text-sm">
                  <Checkbox
                    label="Remember Me"
                    value={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <button
                    type="button"
                    className="underline hover:text-yellow-500 duration-150"
                    onClick={() => onSwitchComponent("forgetPassword")}
                  >
                    Forgot Password
                  </button>
                </div>

                <button type="submit" className="btn-bar mt-5">
                  <LoginIcon className="mr-2" />
                  Login
                </button>

                <button
                  type="button"
                  onClick={() => onSwitchComponent("phoneLogin")}
                  className="btn-bar sso-btn"
                >
                  Login using Phone Number
                </button>
                <span className="text-xs font-medium">OR</span>
                <div>
                  <button
                    type="button"
                    onClick={() => handleThirdPartyLogin("google")}
                    className="bg-[#edae00] w-full text-center text-lg px-5 mb-4 py-2 rounded-full hover:bg-black hover:bg-opacity-60 hover:border-[#edae00] border-2 transition-all duration-300 ease-in-out border-[#edae00] mt-2 sso-btn"
                  >
                    <GoogleIcon className="mb-1 mr-2" />
                    Google
                  </button>
                </div>
                <button
                  type="button"
                  onClick={() => onSwitchComponent("signup")}
                  className="text-lg underline font-semibold sso-btn hover:text-yellow-500 duration-150"
                >
                  Signup
                </button>
              </form>
            </div>
          </article>
        </main>
      </section>
    </div>
  );
};

export default Login;
