/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import "./EmailConfirmation.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { confirmForgotPassword, confirmSignUp, forgotPassword, resendConfirmationCode } from "../../../Services/AuthService";
import { Alert, Snackbar } from "@mui/material";

const OTP_LENGTH = 6;
const RESEND_COOLDOWN = 60;

const EmailConfirmation = ({ activeComponent, onSwitchComponent, email, username }) => {
  const [emailInput, setEmailInput] = useState(username || email || "");
  const [isOtpEnter, setIsOtpEnter] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const [otpInput, setOtpInput] = useState(new Array(OTP_LENGTH).fill(""));
  const otpInputRef = useRef([]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({
    length: true,
    uppercase: true,
    lowercase: true,
    number: true,
    special: true,
    match: true
  });
  const [isResending, setIsResending] = useState(false);
  const [resendError] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("info");

  const validatePassword = () => {
    setPasswordErrors({
      length: password.length < 8,
      uppercase: !/[A-Z]/.test(password),
      lowercase: !/[a-z]/.test(password),
      number: !/\d/.test(password),
      special: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
      match: password !== confirmPassword
    });
  };

  const [resendCooldown, setResendCooldown] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  useEffect(() => {
    let timer;
    if (resendCooldown > 0) {
      timer = setTimeout(() => {
        setResendCooldown(prev => prev - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [resendCooldown]);

  const startResendCooldown = () => {
    setResendCooldown(RESEND_COOLDOWN);
    setIsResendDisabled(true);
  };

  const emailSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(emailInput);
      setIsOtpEnter(true);
      showToast("An OTP has been sent to your email.", "success");
      startResendCooldown();
    } catch (error) {
      console.error("Forgot password error:", error);
      showToast(`Failed to initiate password reset: ${error.message}`, "error");
    }
  };

  const handleResendOTP = async () => {
    if (isResendDisabled) return;

    setIsResending(true);
    try {
      if (activeComponent === "forgetPassword") {
        await forgotPassword(emailInput);
      } else {
        await resendConfirmationCode(emailInput);
      }
      showToast("A new OTP has been sent to your email.", "success");
      startResendCooldown();
    } catch (error) {
      console.error("Resend OTP error:", error);
      showToast(`Failed to resend OTP: ${error.message}`, "error");
    } finally {
      setIsResending(false);
    }
  };



  useEffect(() => {
    if (email) {
      setIsOtpEnter(true);
    }
  }, [email]);

  useEffect(() => {
    validatePassword();
  }, [password, confirmPassword]);



  const showToast = (message, severity = "info") => {
    setToastMessage(message);
    setToastSeverity(severity);
    setOpen(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onOtpSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otpInput.join('');
    console.log("OTP Value: ", otpValue);
    try {
      if (activeComponent === "forgetPassword") {
        setResetCode(otpValue);
        setIsPasswordReset(true);
        setIsOtpEnter(false);
      } else if (activeComponent === "emailConfirm") {
        await confirmSignUp(emailInput, otpValue);
        onSwitchComponent("login");
      }
    } catch (error) {
      console.error("OTP confirmation error:", error);
      showToast(`Failed to confirm OTP: ${error.message}`, "error");
    }
  };

  const passwordResetHandler = async (e) => {
    e.preventDefault();
    if (Object.values(passwordErrors).some(error => error)) {
      return;
    }
    try {
      await confirmForgotPassword(emailInput, resetCode, password);
      setIsResetSuccessful(true);
      showToast("Password reset successful!", "success");
    } catch (error) {
      console.error("Password reset error:", error);
      showToast(`Failed to reset password: ${error.message}`, "error");
    }
  };

  const handleOtpInput = (index, e) => {
    const value = e.target.value;

    if (e.nativeEvent.inputType === 'insertFromPaste') {
      e.preventDefault();
      const pastedData = e.clipboardData.getData('text');
      const sanitizedData = pastedData.replace(/\D/g, '').slice(0, OTP_LENGTH);

      const newOtpInput = new Array(OTP_LENGTH).fill('');
      for (let i = 0; i < sanitizedData.length; i++) {
        newOtpInput[i] = sanitizedData[i];
      }
      setOtpInput(newOtpInput);

      const focusIndex = Math.min(sanitizedData.length, OTP_LENGTH) - 1;
      otpInputRef.current[focusIndex]?.focus();
    } else {
      if (/^[0-9]$/.test(value) || value === "") {
        const newOtpInput = [...otpInput];
        newOtpInput[index] = value;
        setOtpInput(newOtpInput);

        if (value !== "" && index < OTP_LENGTH - 1) {
          otpInputRef.current[index + 1]?.focus();
        }

        if (value === "" && index > 0) {
          otpInputRef.current[index - 1]?.focus();
        }
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const sanitizedData = pastedData.replace(/\D/g, '').slice(0, OTP_LENGTH);

    const newOtpInput = new Array(OTP_LENGTH).fill('');
    for (let i = 0; i < sanitizedData.length; i++) {
      newOtpInput[i] = sanitizedData[i];
    }
    setOtpInput(newOtpInput);

    const focusIndex = Math.min(sanitizedData.length, OTP_LENGTH) - 1;
    otpInputRef.current[focusIndex]?.focus();
  };


  const otpClickHandler = (index) => {
    otpInputRef.current[index]?.select();
  };

  const otpKeydownHandler = (index, e) => {
    if (e.key === "Backspace" && otpInput[index] === "" && index > 0) {
      otpInputRef.current[index - 1]?.focus();
    }
  };

  return (
    <section className="emailConfirmation-section flex flex-col items-center justify-center min-h-screen bg-gray-100 py-12">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleCloseToast}
          severity={toastSeverity}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      {!isOtpEnter && !isPasswordReset && !isResetSuccessful && (
        <>
          <h1 className="w-fit font-bold text-4xl border-b-2 border-yellow-500 py-9 mb-16">
            {activeComponent === "forgetPassword" ? "Reset Password" : "Confirm Email"}
          </h1>
          <form onSubmit={emailSubmitHandler} className="flex flex-col items-center justify-center w-full max-w-md space-y-4 email-form">
            <input
              type="email"
              placeholder="Email address"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              className="input-bar mb-4 border border-gray-300"
              required
            />
            <button type="submit" className="btn-bar-email bg-yellow-500 text-white py-2 px-4 rounded-md w-full">
              {activeComponent === "forgetPassword" ? "Send Reset Code" : "Submit"}
            </button>
            <button
              type="button"
              onClick={() => onSwitchComponent('login')}
              className="mt-4 text-base underline hover:text-yellow-500 transition-colors duration-300"
            >
              Back to Email Login
            </button>
          </form>
        </>
      )}

      {isOtpEnter && (
        <>
          <form onSubmit={onOtpSubmit} className="flex flex-col items-center justify-center w-full max-w-md space-y-4 email-form">
            <h1 className="text-4xl font-bold border-b-2 border-b-yellow-500 pb-10 mb-14">{"Enter OTP"}</h1>
            <div className="otp-container flex space-x-2">
              {otpInput.map((digit, idx) => (
                <input
                  key={idx}
                  type="text"
                  maxLength="1"
                  value={digit}
                  ref={el => otpInputRef.current[idx] = el}
                  onChange={(e) => handleOtpInput(idx, e)}
                  onClick={() => otpClickHandler(idx)}
                  onPaste={handlePaste}
                  onKeyDown={(e) => otpKeydownHandler(idx, e)}
                  className="otp-input size-10 border border-gray-300 text-center text-lg rounded-md text-[#595959] font-bold outline-none"
                />
              ))}
            </div>
            <button type="submit" className="btn-bar-email bg-yellow-500 text-white py-2 px-4 rounded-md w-full">
              Confirm OTP
            </button>
          </form>
          <button
            onClick={handleResendOTP}
            disabled={isResendDisabled || isResending}
            className={`mt-4 text-base ${isResendDisabled ? 'text-gray-400' : 'text-yellow-500'} underline`}
          >
            {isResending ? "Resending..." : isResendDisabled ? `Resend OTP (${resendCooldown}s)` : "Resend OTP"}
          </button>
          {resendError && <p className="text-red-500 mt-2">{resendError}</p>}
        </>
      )}

      {isPasswordReset && !isResetSuccessful && (
        <>
          <h1 className="w-fit font-bold text-4xl border-b-2 border-yellow-500 py-9 mb-16">Reset Password</h1>
          <form onSubmit={passwordResetHandler} className="flex flex-col items-center justify-center w-full max-w-md space-y-4 email-form">
            <div className="w-full mb-5 relative">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder="New Password"
                required
                onChange={(e) => setPassword(e.target.value)}
                className="input-bar  border border-gray-300"
              />
              <button
                type="button"
                className="absolute right-3 top-3 text-gray-600"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityIcon className="visibility-icon" /> : <VisibilityOffIcon className="visibility-iconOff" />}
              </button>
            </div>
            <div className="w-full mb-5 relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                placeholder="Confirm New Password"
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="input-bar border border-gray-300"
              />
              <button
                type="button"
                className="absolute right-3 top-3 text-gray-600"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <VisibilityIcon className="visibility-icon" /> : <VisibilityOffIcon className="visibility-iconOff" />}
              </button>
            </div>
            <div className="w-full text-xs space-y-1">
              {passwordErrors.length && <p className="text-red-500">Password must be at least 8 characters long</p>}
              {passwordErrors.uppercase && <p className="text-red-500">Password must contain at least one uppercase letter</p>}
              {passwordErrors.lowercase && <p className="text-red-500">Password must contain at least one lowercase letter</p>}
              {passwordErrors.number && <p className="text-red-500">Password must contain at least one number</p>}
              {passwordErrors.special && <p className="text-red-500">Password must contain at least one special character</p>}
              {passwordErrors.match && <p className="text-red-500">Passwords do not match</p>}
            </div>
            <button
              type="submit"
              className="btn-bar-email bg-yellow-500 text-white py-2 px-4 rounded-md w-full mt-4"
              disabled={Object.values(passwordErrors).some(error => error)}
            >
              Reset Password
            </button>
          </form>
        </>
      )}

      {isResetSuccessful && (
        <div className="flex flex-col items-center justify-center">
          <h1 className="w-fit font-bold text-xl border-b-2 border-yellow-500 py-9 mb-16">Password Reset Successful</h1>
          <p className="text-base mb-8 text-center font-semibold">Your password has been successfully reset.</p>
          <button
            onClick={() => onSwitchComponent('login')}
            className="bg-yellow-500 text-lg px-5 py-2 rounded-full hover:bg-black hover:bg-opacity-60 hover:border-yellow-500 border-2 transition-all duration-300 ease-in-out border-yellow-500 font-semibold"
          >
            Back to Login
          </button>
        </div>
      )}
    </section>
  );
};

export default EmailConfirmation;